var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ctData.attributes)?_c('div',{staticClass:"no-header-template doppio-layer pattern-bg",attrs:{"id":"totop","title":""}},[_c('div',{staticClass:"header-esperto",attrs:{"title":""}},[_c('div',{staticClass:"pattern-bg"},[_c('div',{class:{ 
                'scheda': true, 'white-bg': true, 'backstep': true
                }},[_c('div',{staticClass:"profilo"},[_c('images',{attrs:{"imagedata":this.ctData,"fieldname":'field_expert_photo',"breakstyles":{'0': 'xs_image', '300': 'sm_image'}}})],1),_c('div',{staticClass:"elements container-espe"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"fields"},_vm._l((this.expertFields),function(f,index){return _c('div',{key:'field'+index,staticClass:"field",style:(_vm.fieldStyle(f.attributes.drupal_internal__tid))})}),0),_c('h1',{staticClass:"name"},[_vm._v(_vm._s(_vm.ctData.attributes.field_name + " " + _vm.ctData.attributes.field_surname))]),_c('p',{staticClass:"role"},[_vm._v(_vm._s(this.ctData.attributes.field_expert_professione))])]),_c('div',{staticClass:"incontri"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(this.ctData.attributes.field_disponibilita_in_ore))])])])])])]),(this.ctData.attributes.field_expert_bio.summary || this.ctData.attributes.field_expert_interessi.length>0)?_c('div',{staticClass:"patern-bg"},[_c('div',{class:{
                //'backstep': true, 
                'container-espe': true, 'gray-bg': true, 
                'endborder': this.ctData.attributes.field_expert_interessi.length>0 }},[_c('p',[_vm._v(" "+_vm._s(this.ctData.attributes.field_expert_bio.summary)+" ")]),(this.ctData.attributes.field_expert_domande.length>0)?_c('h3',[_vm._v("ALCUNE DOMANDE CHE PUOI FARMI")]):_vm._e(),_c('ul',_vm._l((this.ctData.attributes.field_expert_domande),function(d,index){return _c('li',{key:'domanda'+index},[_vm._v(_vm._s(d))])}),0),_c('br')])]):_vm._e(),_c('div',{staticClass:"gray-bg"},[(this.ctData.attributes.field_expert_interessi.length>0)?_c('div',{staticClass:"container-espe backstep pattern-bg"},[_c('h3',[_vm._v("INTERESSI E COMPETENZE")]),_c('div',{staticClass:"tags"},_vm._l((_vm.ctData.attributes.field_expert_interessi),function(tag,index){return _c('div',{key:'tag'+index,staticClass:"tag"},[_vm._v(" "+_vm._s(tag)+" ")])}),0)]):_vm._e()]),(this.ctData.attributes.field_expert_motto)?_c('div',{staticClass:"container-espe pattern-bg big-quote"},[_c('h3',{staticClass:"gray-quote-block dark-blue-bg"},[_vm._v(" "+_vm._s(this.ctData.attributes.field_expert_motto)+" ")]),_c('h3',{staticClass:"gray-quote-author dark-blue-bg endborder"},[_vm._v(" "+_vm._s(this.ctData.attributes.field_expert_autore_citazione)+" ")])]):_vm._e(),_c('div',{staticClass:"dark-blue-bg"},[_c('div',{class:{
                'container-espe': true, 'pattern-bg': true, 
                'backstep': this.ctData.attributes.field_expert_motto }},[_c('h3',{staticClass:"bio-title"},[_vm._v("BIOGRAFIA")]),_c('div',{staticClass:"bio-text",domProps:{"innerHTML":_vm._s(this.ctData.attributes.field_expert_bio.processed)}})])]),(this.ctData.attributes.field_disponibilita_in_ore > 0)?_c('div',{staticClass:"button-container richiedi-confronto"},[_c('router-link',{staticClass:"btn",attrs:{"to":"/richiedi-confronto"}},[_vm._v(" RICHIEDI UN CONFRONTO ")])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }