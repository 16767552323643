<template>
    <div id="totop" title="" class="no-header-template doppio-layer pattern-bg" v-if="ctData.attributes">

        <div class="header-esperto" title="">
            <!-- <div class="container-espe">
                <router-link class="indietro" to="/gli-esperti"></router-link>
            </div> -->
            <div class="pattern-bg">
            <div :class="{ 
                    'scheda': true, 'white-bg': true, 'backstep': true
                    }">
                <div class="profilo">
                    <images :imagedata="this.ctData" :fieldname="'field_expert_photo'" :breakstyles="{'0': 'xs_image', '300': 'sm_image'}"></images>
                </div>
                <div class="elements container-espe">
                    <div class="info">
                        <div class="fields">
                            <div class="field" v-for="(f, index) in this.expertFields" :key="'field'+index" :style="fieldStyle(f.attributes.drupal_internal__tid)">
                                
                            </div>
                        </div>
                        <h1 class="name">{{ ctData.attributes.field_name + " " + ctData.attributes.field_surname }}</h1>
                        <p class="role">{{ this.ctData.attributes.field_expert_professione }}</p>
                    </div>
                    <div class="incontri">
                        <div class="num">{{ this.ctData.attributes.field_disponibilita_in_ore }}</div>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="patern-bg" v-if="this.ctData.attributes.field_expert_bio.summary || this.ctData.attributes.field_expert_interessi.length>0">
            <div :class="{
                    //'backstep': true, 
                    'container-espe': true, 'gray-bg': true, 
                    'endborder': this.ctData.attributes.field_expert_interessi.length>0 }">
                <p> {{ this.ctData.attributes.field_expert_bio.summary }} </p>

                    <h3 v-if="this.ctData.attributes.field_expert_domande.length>0">ALCUNE DOMANDE CHE PUOI FARMI</h3>
                    <ul>
                        <li v-for="(d, index) in this.ctData.attributes.field_expert_domande" :key="'domanda'+index">{{ d }}</li>
                    </ul>
                    <br>
            </div>
        </div>

        <div class="gray-bg">
        <div v-if="this.ctData.attributes.field_expert_interessi.length>0" class="container-espe backstep pattern-bg">
            <h3>INTERESSI E COMPETENZE</h3>
            <div class="tags">
                <div class="tag" v-for="(tag, index) in ctData.attributes.field_expert_interessi" :key="'tag'+index">
                    {{ tag }}
                </div>
            </div>
        </div>
        </div>

        <div v-if="this.ctData.attributes.field_expert_motto" class="container-espe pattern-bg big-quote">
            <h3 class="gray-quote-block dark-blue-bg">
                {{ this.ctData.attributes.field_expert_motto }}
            </h3>
            <h3 class="gray-quote-author dark-blue-bg endborder">
                {{ this.ctData.attributes.field_expert_autore_citazione }}
            </h3>
        </div>

        <div class="dark-blue-bg">
            <div :class="{
                    'container-espe': true, 'pattern-bg': true, 
                    'backstep': this.ctData.attributes.field_expert_motto }">
                <h3 class="bio-title">BIOGRAFIA</h3>
                <div class="bio-text" v-html="this.ctData.attributes.field_expert_bio.processed"></div>
            </div>
        </div>

        <div v-if="this.ctData.attributes.field_disponibilita_in_ore > 0" class="button-container richiedi-confronto">
            <router-link class="btn" to="/richiedi-confronto">
                RICHIEDI UN CONFRONTO
            </router-link>
        </div>

    </div>
</template>

<script>
import { fetchNodes, fetchRoutes, fetchTaxonomy } from '../../libs/drupalClient'
import { fieldColorExpert, fieldSrcExpert } from "../../libs/utils"
import Images from '../../components/Images.vue'

export default {
    name: 'expert',
    components: {
        Images
    },
    data() {
        return {
            notfound: true,
            ctData: {},
            filtersData: []
        }
    },
    computed: {
        profilephoto() {
            return this.ctData.field_expert_photo ? (process.env.VUE_APP_ENDPOINT + this.ctData.field_expert_photo.attributes.uri.url) : ""
        },
        expertFields() {
            var array = []
            if(this.ctData.field_expert_ambito)
                this.ctData.field_expert_ambito.forEach(f => {
                    var find = this.filtersData.find(ff => f.attributes.name.toLowerCase() == ff.attributes.name.toLowerCase())
                    if(find)
                        array.push(find)
                })
            return array
        },
        objectid() {
            return this.$route.params.nid
        },
        objecttitle() {
            return this.$route.params.title
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    methods: {
        fieldStyle(image) {
            var f = this.filtersData.find(i => i.attributes.drupal_internal__tid==image)
            var n = f.attributes.name
            return '--background-color: ' + fieldColorExpert(image) + ';' +
                '--background-image: url(' + fieldSrcExpert(image) + ');' +
                '--field-text: "' + n + '";' + 
                '--field-width: ' + n.length*8 + 'px;'
        },
        findCT() {
            fetchRoutes().then(res => {
                res.routes.forEach(r => {
                    //al caricamento della pagina controlla se esiste il ct richiesto
                    //sia con internal_ID che con titolo definito dal back (che finisce in URL)
                    if(parseInt(r.nid)==this.objectid && r.title==this.objecttitle && r.lang==this.lang) {
                        this.notfound = false
                    }
                })

                if(!this.notfound) {
                    //normale fetch al caricamento della pagina
                    //fetch fatto con ID ma solo se esise con quel titolo corrispondente
                    fetchNodes('expert', {
                        filters: [{
                            key: 'drupal_internal__nid',
                            value: this.objectid
                        }],
                        include: ['field_expert_photo', 'field_expert_ambito']
                    }, this.lang).then(res => {
                        this.ctData = res[0]
                    })
                } else {
                    //se non trova la combinazione id+titolo, reindirizza in pagina di errore
                    this.$router.push('/404/not-found')
                }
            })
        }
    },
    mounted() {
        this.findCT()
        
        fetchTaxonomy('ambito_di_esperienza', {}, this.lang).then(res => {
            this.filtersData = res
            //console.log(this.filtersData)
        })

        this.$store.commit('SET_EXPERT', this.objectid)
        localStorage.setItem('LS_REQUEST_EXPERT', this.objectid)
    }
}
</script>

<style lang="scss" scoped>
.bio-title { 
    margin-top: 0; 
    padding-top: 1em; 
}
.bio-text { padding-bottom: 1em; }

.richiedi-confronto {
    position: fixed;
    bottom: 30px;
    right: 0;
    z-index: 10000;
    .btn {
        padding: 1.5em 2em;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 992px) {
    .richiedi-confronto {
        bottom: 30px;
        right: 30px;
        .btn {
            border-radius: 50px;
        }
    }
}
</style>